import React from 'react';
import './index.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import VoiceBot from './pages/VoiceBot';
import FormGenerator from './SheetForm/FormGenerator';
import GoogleFormClone from './SheetForm/GoogleFormClone';
// import VoiceBot from './components/VoiceBot'; // Adjust the path to your component
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { ThemeProvider } from './contexts/ThemeContext';
import { ChatbotConfigProvider } from './contexts/ChatbotConfigContext';
import { customBranding } from './config/config';

const App = () => {
  React.useEffect(() => {
    // Set the CSS variables for primary color and scrollbar color dynamically

    document.documentElement.style.setProperty('--scrollbar-color', customBranding.scrollbarColor);
    document.documentElement.style.setProperty('--scrollbar-track-color', customBranding.scrollbarTrackColor);
  }, []);
  return (
    <BrowserRouter>
      <ChatbotConfigProvider>
        <Routes>
          <Route path="/" element={<VoiceBot />} />
          <Route path="/form-generator" element={<FormGenerator />} />
          <Route path="/GoogleFormClone" element={<GoogleFormClone />} />
        </Routes>
        
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ChatbotConfigProvider>
    </BrowserRouter>
  );
};



export default App
