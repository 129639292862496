// src/utils/sessionUtils.js

export const setLoginTimestamp = () => {
    localStorage.setItem('loginTimestamp', Date.now().toString());
  };
  
  export const clearLoginTimestamp = () => {
    localStorage.removeItem('loginTimestamp');
  };
  
  export const checkSessionExpiration = () => {
    const loginTimestamp = localStorage.getItem('loginTimestamp');
    if (!loginTimestamp) return false;
  
    const currentTime = Date.now();
    const oneHourInMilliseconds =24 * 60 * 60 * 1000; // 1 hour
    const isExpired = (currentTime - parseInt(loginTimestamp)) > oneHourInMilliseconds;
  
    return isExpired;
  };