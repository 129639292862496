import React, { createContext, useState, useContext, useEffect } from 'react';
import { customBranding, apiEndpoints } from '../config/config';

const ChatbotConfigContext = createContext();
// API service for chatbot configuration
const fetchChatbotConfig = async (accessToken) => {
  try {
    console.log('Making API request with token:', accessToken);
    
    const response = await fetch(apiEndpoints.chatbot_config, {
      method: 'GET',
      headers: {
        'clientToken': `Bearer ${accessToken}`,
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      console.error('API Response not OK:', response.status, response.statusText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Raw API Response:', data);
    return data;
  } catch (error) {
    console.error('Error fetching chatbot config:', {
      message: error.message,
      stack: error.stack,
      error
    });
    throw error;
  }
};


export const ChatbotConfigProvider = ({ children }) => {
  // const [config, setConfig] = useState({
  //   ...customBranding,
  //   name: 'Bolofy',
  //   website: 'https://bolofy.com',
  //   description: ''
  // });

  const [config, setConfig] = useState(() => {
    // Check if we're in an expanded window and have config in URL
    if (window.opener) {
      const urlParams = new URLSearchParams(window.location.search);
      const expandedConfig = urlParams.get('expandedConfig');
      if (expandedConfig) {
        try {
          return JSON.parse(decodeURIComponent(expandedConfig));
        } catch (error) {
          console.error('Error parsing expanded config:', error);
        }
      }
    }
    // Fall back to default config
    return customBranding;
  });


  // useEffect(() => {
  //   const handleConfigUpdate = (event) => {
  //     // Log the received message
  //     console.log('Received message:', event);

  //     // Handle configuration update
  //     if (event.data?.type === 'UPDATE_CONFIG') {
  //       console.log('Updating config with:', event.data.payload);
  //       setConfig(prevConfig => ({
  //         ...prevConfig,
  //         ...event.data.payload,
  //         color: event.data.payload.color || prevConfig.color,
  //         // Update other customBranding properties as needed
  //         customBranding: {
  //           ...prevConfig.customBranding,
  //           color: event.data.payload.color || prevConfig.color,
  //         }
  //       }));
  //     }
  //   };

  //   window.addEventListener('message', handleConfigUpdate);
    
  //   // Cleanup
  //   return () => window.removeEventListener('message', handleConfigUpdate);
  // }, []);

// Effect to handle configuration updates from API
  useEffect(() => {
    const initializeConfig = async () => {
      try {
        // Get access token from URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const accessToken = urlParams.get('accessToken');

        if (!accessToken) {
          console.warn('No access token found in URL parameters');
          return;
        }

        // Fetch configuration from API
        const apiResponse = await fetchChatbotConfig(accessToken);
        // console.log('API Response in ChatbotConfig:', apiResponse);
        // Prepare configuration object
        const newConfig = {
          id: apiResponse.id || 1,
          color: apiResponse.color || customBranding.color,
          logo_url: apiResponse.logo_url || customBranding.logo_url,
          greetingMessage: apiResponse.greetingMessage || "How can I help you?",
          isContactForm: Number(apiResponse.isContactForm ?? true),
          // isContactForm: apiResponse.isContactForm ?? true,
          isVoice: apiResponse.isVoice ?? true,
          name: apiResponse.name || 'Bolofy',
          website: apiResponse.website || 'https://bolofy.com',
          description: apiResponse.description || '',
          accessToken: accessToken,
          // Preserve other customBranding properties
          ...customBranding,
          // Override with API response values
          ...apiResponse
        };

        setConfig(newConfig);
        console.log('Configuration updated:', newConfig);

      } catch (error) {
        console.error('Error initializing configuration:', error);
        // Keep using existing config on error
      }
    };

 
     // Skip function execution if running on localhost
     if (window.location.hostname !== 'localhost') {
      initializeConfig();
  } else {
    console.log('Skipping configuration initialization on localhost');
  }
  }, []); // Empty dependency array - only run once on mount


  useEffect(() => {
    const handleConfigUpdate = (event) => {
      if (event.data?.type === 'UPDATE_CONFIG') {
        console.log('Received new config:', event.data.payload);
        // setConfig(event.data.payload);
        setConfig(prevConfig => ({
          ...prevConfig,
          ...event.data.payload
        }));
      }
    };

    window.addEventListener('message', handleConfigUpdate);
    return () => window.removeEventListener('message', handleConfigUpdate);
  }, []);

  // Persist config changes to URL in expanded window
  useEffect(() => {
    if (window.opener) {
      const currentUrl = new URL(window.location.href);
      const configParams = encodeURIComponent(JSON.stringify(config));
      currentUrl.searchParams.set('expandedConfig', configParams);
      window.history.replaceState({}, '', currentUrl);
    }
  }, [config]);
  
  return (
    <ChatbotConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ChatbotConfigContext.Provider>
  );
};

export const useChatbotConfig = () => {
  const context = useContext(ChatbotConfigContext);
  if (!context) {
    throw new Error('useChatbotConfig must be used within a ChatbotConfigProvider');
  }
  return context;
};