import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  FileText,
  Mail,
  MessageSquare,
  ChevronRight,
  CheckCircle,
  RefreshCw,
  User
} from 'lucide-react';

const GoogleFormClone = () => {
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({});
  const [activeField, setActiveField] = useState(null);

  const formFields = [
    {
      id: 'name',
      type: 'text',
      label: 'Full Name',
      required: true,
      
    },
    {
      id: 'email',
      type: 'email',
      label: 'Email Address',
      required: true,
    },
    {
      id: 'feedback',
      type: 'textarea',
      label: 'Feedback',
      required: false,
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.4 }
    }
  };

  const fieldVariants = {
    initial: { scale: 1, y: 20, opacity: 0 },
    animate: { 
      scale: 1, 
      y: 0, 
      opacity: 1,
      transition: { duration: 0.5 }
    },
    hover: { 
      scale: 1.02,
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      transition: { duration: 0.2 }
    },
    focus: {
      scale: 1.02,
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  const handleInputChange = (id, value) => {
    setFormData(prev => ({
      ...prev,
      [id]: value
    }));
  };

  if (submitted) {
    return (
      <motion.div 
        className="min-h-screen flex items-center justify-center p-4"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="w-full max-w-2xl bg-gradient-to-br from-cyan-50 via-white to-cyan-50 p-8 rounded-2xl shadow-xl">
          <div className="text-center">
            <motion.div
              initial={{ scale: 0, rotate: -180 }}
              animate={{ scale: 1, rotate: 0 }}
              transition={{ type: "spring", stiffness: 200, damping: 20 }}
            >
              <CheckCircle className="w-20 h-20 mx-auto text-cyan-500 mb-6" />
            </motion.div>
            <motion.h2 
              className="text-3xl font-bold text-gray-800 mb-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              Thank you!
            </motion.h2>
            <motion.p 
              className="text-gray-600 text-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              Your response has been recorded successfully.
            </motion.p>
            <motion.button
              onClick={() => setSubmitted(false)}
              className="mt-8 px-8 py-3 bg-gradient-to-r from-cyan-600 to-slate-600 text-white rounded-xl shadow-lg hover:shadow-xl flex items-center gap-2 mx-auto text-lg font-medium"
              whileHover={{ scale: 1.05, boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)" }}
              whileTap={{ scale: 0.95 }}
            >
              <RefreshCw className="w-5 h-5" />
              Submit another response
            </motion.button>
          </div>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div 
      className="min-h-screen flex items-center justify-center p-4"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="w-full max-w-2xl bg-white rounded-2xl shadow-xl overflow-hidden">
        <div className="bg-gradient-to-r from-cyan-600 to-slate-600 p-8">
          <h1 className="text-3xl font-bold text-white flex items-center gap-3">
            <FileText className="w-8 h-8" />
            Contact Form
          </h1>
          <p className="mt-3 text-cyan-100 text-lg">
            Please fill out this form with your information.
          </p>
        </div>

        <form onSubmit={handleSubmit} className="p-8 space-y-6">
          <AnimatePresence>
            {formFields.map((field, index) => (
              <motion.div
                key={field.id}
                className={`bg-white p-6 rounded-xl border-2 transition-all duration-200 ${
                  activeField === field.id ? 'border-cyan-500 shadow-lg' : 'border-gray-100 hover:border-cyan-200'
                }`}
                variants={fieldVariants}
                initial="initial"
                animate="animate"
                whileHover="hover"
                custom={index}
                transition={{ delay: index * 0.1 }}
              >
                <label className="block">
                  <span className="text-gray-800 font-semibold text-lg flex items-center gap-2 mb-2">
                    {field.icon}
                    {field.label}
                    {field.required && (
                      <span className="text-red-500">*</span>
                    )}
                  </span>
                  <p className="text-gray-500 text-sm mb-3 ml-7">
                    {field.description}
                  </p>
                  {field.type === 'textarea' ? (
                    <textarea
                      required={field.required}
                      onChange={(e) => handleInputChange(field.id, e.target.value)}
                      onFocus={() => setActiveField(field.id)}
                      onBlur={() => setActiveField(null)}
                      className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-cyan-500  outline-none focus:ring focus:ring-cyan-200 focus:ring-opacity-50 transition-all duration-200 resize-none min-h-[120px]"
                      placeholder={`Enter your ${field.label.toLowerCase()}`}
                    />
                  ) : (
                    <input
                      type={field.type}
                      required={field.required}
                      onChange={(e) => handleInputChange(field.id, e.target.value)}
                      onFocus={() => setActiveField(field.id)}
                      onBlur={() => setActiveField(null)}
                      className="w-full h-12 px-4 rounded-xl border border-gray-200 focus:border-cyan-500 outline-none focus:ring focus:ring-cyan-200 focus:ring-opacity-50 transition-all duration-200"
                      placeholder={`Enter your ${field.label.toLowerCase()}`}
                    />
                  )}
                </label>
              </motion.div>
            ))}
          </AnimatePresence>

          <div className="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 pt-6">
            <p className="text-rose-500 text-sm flex items-center gap-1">
                *
              Required fields
            </p>
            <motion.button
              type="submit"
              className="w-full sm:w-auto px-8 py-3 bg-gradient-to-r from-cyan-600 to-slate-600 text-white rounded-xl shadow-lg hover:shadow-xl flex items-center justify-center gap-2 text-lg font-medium"
              whileHover={{ scale: 1.05, boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)" }}
              whileTap={{ scale: 0.95 }}
            >
              Submit Form
              <ChevronRight className="w-5 h-5" />
            </motion.button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default GoogleFormClone;